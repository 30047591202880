import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import SignUpComponent from "../components/sign-up"

const VisibleAndVocalPage = ({ location }) => {
  return (
    <Layout>
      <SEO
        location={location}
        image="/images/visible-and-vocal.png"
        title="Visible & Vocal"
        description="On February 4th, 2021 POCAM presented Visible & Vocal - The Canadian BIPOC in Advertising and Marketing Study. View the presentation, and download the report here."
      />
      <section className="bg-dark text-white py-4 py-lg-5">
        <Container>
          <Row className="mb-4">
            <Col md="7">
              <h1 className="display-4 mb-3">Visible & Vocal</h1>
              <h2 className="mb-0">
                The Canadian advertising and marketing study for BIPOC professionals
              </h2>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-4 py-lg-5">
        <Container>
          <Row>
            <Col sm="8">
              <p className="lead">
                POCAM’s annual Visible & Vocal study is the only Canadian survey by BIPOC professionals, for BIPOC professionals—amplifying voices that often go unheard in the advertising and marketing industry. Each year, our study brings together diverse perspectives to highlight the workplace experiences of BIPOC professionals in Canada.
              </p>
              <p>
                We believe change starts with being visible and vocal.
              </p>
              <p>
                By participating in this completely anonymous survey, you help:
              </p>
              <ul>
                <li>Illuminate the challenges of working within systemically white environments.</li>
                <li>Track progress on key issues like bias, racism, workplace discrimination, career growth, and leadership representation.</li>
                <li>Hold our industry accountable for building equitable and inclusive workplaces.</li>
                <li>Showcase the diversity of talent within Canada’s advertising and marketing industry.</li>
              </ul>
              <p>
                Your voice matters. Make it count.
              </p>
              <br></br>
              <p>
                <strong>Take the survey today to help amplify BIPOC voices and drive meaningful industry change.</strong>
              </p>
              <p>
                Want to see last year’s survey results and insights on workplace diversity, equity and inclusion? <a href="https://drive.google.com/file/d/1ZbkzhiS_JxDN0ad88VScFLCzSPhsWF_9/view?usp=sharing" rel="noreferrer" target="_blank">Download the presentation here</a>.
              </p>
            </Col>
            <Col className="text-md-right">
              <a
                className="btn btn-primary btn-lg mr-3"
                rel="noreferrer"
                href="https://rebrand.ly/visible-and-vocal"
                target="_blank"
              >
                Take the survey
              </a>
            </Col>
          </Row>
        </Container>
      </section>
      <SignUpComponent />
    </Layout>
  )
}

export default VisibleAndVocalPage
